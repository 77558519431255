

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.CreateBook {
  background-color: #f0f6f6;
  min-height: 100vh;
  color: white;
}

.ShowBookDetails {
  background-color: #f0f6f6;
  min-height: 100vh;
  color: white;
}

.UpdateBookInfo {
  background-color: #f0f6f6;
  min-height: 100vh;
  color: white;
}

.ShowBookList {
  background-color: #f0f6f6;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100px;
  color: white;
  float: left;
}


/* BookList Styles */
.list {
  display: inline;
  margin: 20px 0 50px 0;
}

.card-container {
  width: 80%;
  border: 1px solid #999;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  margin-top:6px;
  background:#c5d9d8;
}
.card-container1 {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  margin-top:6px;
  background:#ddd;
  
}

.desc {
  height: 130px;
  padding: 10px;
}
.card-container1 .desc {padding:0; height:auto;}

.desc h2 {
  font-size: 1em;
  font-weight: 400;
}

.desc h3, p {
  font-weight: 300;
}

.desc h3 {
  color: #6c757d;
  font-size: 1em;
  padding: 10px 0 10px 0;
}

.top-header{background:#18192b; padding:10px 0; float: left; width: 100%;}
.top-header ul {padding:0; padding-top: 15px; margin: 0; float: right;}
.top-header li {list-style: none; display: inline; padding:10px; margin:0 5px;}

.button-nav {padding:8px 20px; background-color: #3aaf9f; border-radius: 12px 0 12px 12px; box-shadow: 1px 1px 10px 0px #999;}
.top-header a {color:#fff !important; line-height: 40px;}

.banner-header {text-align: center; display:table; float: left; width: 100%; background-image: url(./img/banner.jpg); min-height: 450px;}
.banner-header h1 {width: 100%; font-weight: bold; vertical-align: middle; text-align: center; width: 100%; color:#fff; font-size:50pt;}
.banner-header p {color:#fff; font-size:30px; padding:0 50px;}
.banner-header {padding-top:120px}
.middle-section{float:left; width: 100%; margin:80px 0; }
.middle {max-width:800px; width:100%; margin:0 auto; }
.left_section{text-align:center; display:table; background-image: url(./img/left-bg.png); border-radius: 20px 0 0 20px; min-height: 600px; background-size: cover;}
.right_section{display:table; text-align: center; background:#c5d9d9; min-height: 500px; background-size: cover; border-radius: 0px 20px 20px 0;}
.left_section h3{ color:#fff;  width: 100%; font-size: 28pt; font-weight: bold;}
.left_section p { color:#fff;  width: 100%; padding: 8px 0 18px; font-size: 12pt}
.content-left, .content-right {display: table-cell; vertical-align: middle;}
a.button-nav, a.button-nav1 {color:#fff; text-decoration: none;}
.social li {list-style: none; float:left; border-radius: 50%; padding:0px 10px 10px; display: inline-block; margin:10px 5px; border:1px solid #333; width:40px; height:40px;}
.social li img { width:100%; }

.form-register p {font-weight: 600;}
.form-register form input {width: 100%; margin: 5px 0; border:1px #ddd; padding:10px 10px 10px 40px}
.form-register form input[type="submit"] {padding:10px;}
.form-register form input[type="submit"], .card-box form input[type="submit"]{width: auto; margin-top: 15px; color:#fff; cursor:pointer;  }
.form-register form {margin:0 auto; width:100%; max-width: 200px;}

footer{margin:0; padding:0; float: left; width: 100%; background-image: url(./img/footer-bg.png); background-repeat: no-repeat; background-position: center top; padding-top:280px; min-height:200px}
footer li {list-style: none; padding:1px 0; color:#18192b;}
footer li span {padding-left:5px;}
footer .container .row .col-md-3:first-child{padding-top:60px}
footer .container .col-md-3,  footer .container .col-md-4, footer .container .col-md-2{padding:0px 15px}
footer  h2 {font-size:20pt; font-weight: 600;}

footer .copyright {float: left; width:100%; padding:30px 0; text-align: center; background:#18192b}
footer .copyright p {color:#fff; font-weight: 600; margin:0}
footer .col-md-4 ul {width:100%; margin-top: 10px; padding-left:10px}
footer .col-md-4 input{border:1px #ddd; border-radius: 12px 0 12px 12px;  padding:8px 12px; max-width: 200px; margin-right: 10px;}
footer .col-md-4 li {display: inline;padding:0 5px}
footer input[type="submit"] {cursor:pointer;  border-radius: 12px 0 12px 12px; background:#18192b; padding:8px 12px; color:#fff}
.left_section.login {border-radius: 0 20px 20px 0; background-image: url(./img/login-right-bg.png);}
.right_section.login {border-radius: 20px 0 0 20px;}
a, link {text-decoration: none;}
.button-nav1{padding:8px 20px; border:1px solid #fff; border-radius: 15px 0 15px 15px; box-shadow: 1px 1px 10px 0px #999;}
.social {padding:20px 0; margin:0 auto; width:100%; max-width:110px;}
.content-right h3 {font-weight: 600;}
.reset-password h3 {padding:60px; font-weight: 700;}

.form-register input[type="email"] {background:url(./img/email-icon.png); background-repeat:no-repeat; background-position:10px center; background-size:20px; background-color:#fff;}
.form-register input[name="name"] {background:url(./img/name-icon.png); background-repeat:no-repeat; background-position:10px center; background-size:20px; background-color:#fff;}
.form-register input[type="password"] {background:url(./img/pass-icon.png); background-repeat:no-repeat; background-position:10px center; background-size:20px; background-color:#fff;}

.reset-password input[type="email"] {margin-bottom:20px; background:url(./img/email-icon.png); background-repeat:no-repeat; background-position:center right 15px; background-size:20px; background-color:#fff; padding:5px 10px 5px 10px;}

.reset-password .form-register p {padding: 15px 0;}
.form-register p {margin:12px 0}
.form-register p a, .form-register p  {color:#666; font-size:16px}


/* dashboard panel */

.dashboard-top-header {background:#18192B; padding:0;}
.dashboard-top-header .col-md-2 {border-right:1px solid #ddd;}
.dashboard-top-header .col-md-2 img {width:90px;}
.left_menu {float:left; background:#fff; height:100%; width: 100%;  border-right:#ddd 1px solid;}
.left_menu ul {padding-left:0px;}
.left_menu ul li {list-style: none; display: block; border-bottom:2px solid #ddd; padding:10px 0}
.left_menu ul li a {color:#777; padding-left:30px}
.main-area .col-md-10{width:100%; background:#f0f6f6;}
.main-area .dashboard-right {padding:40px 70px; width:100%;}
.main-area .dashboard-right h1 {font-size:30px; font-weight: 600;}
.card-box {background:#fff; padding:30px 50px; margin-top:40px; border-radius: 15px;}
.card-box h4 {padding-bottom:20px; border-bottom:1px solid #ddd;}
.profile .form-check .check-box{padding:12px 30px 16px; background: #f0f6f6; margin:15px 5px; width:100%; border:1px solid #999;}
.profile .form-check .check-box label {font-size:14px; color:#444}
.form-check-input {margin-top:0.4rem;}

.form-check-radio {background:none !important; padding:0 !important;}
.dash_menu {padding:0;}

.menu-right {padding:0}
.dashboard-right {background:#F0F6F6; padding:0 80px 30px;}
.dashboard-right h1 {padding-top: 50px; font-weight: 600; font-size:28px}
.content-right h1 {font-weight: 600; color:#000}

.request .form-check .check-box{padding:0px 30px; margin:0px 20px; width:100%;}
.request .form-check .check-box label {font-size:14px; color:#444 }
.request .form-group > label {font-weight:600;}
.form-group label span {color:red; padding-left: 3px;}
.active_dash {background:#666;}
.active_dash a {color:#fff !important;}
h3.title { font-size:40px; line-height: 54px;}
.title_right {font-size:38px;  line-height: 50px; padding:0 30px;}
.otp {margin-top: 40px !important;}
.middle_thanks {max-width:1366px; text-align: center; width:100%; margin:0 auto; border: 1px solid #ddd; padding:80px 30px; }
.middle_thanks h5 {line-height: 36px; font-size:24px}
.middle_thanks h3 {font-size:40px; font-weight: 600; padding-bottom: 20px;}
#digit-1 {float:left; margin:0 5px; margin-bottom:40px; width:40px; padding:10px 15px ; background:transparent; border-radius: 0; border-bottom: 4px solid #fff;}
.form-register {margin-bottom:20px ;}
.dashboard-right.thanks_msg{background:transparent;padding: 50px;
  text-align: center;}
  .dashboard-right.thanks_msg h3{ font-size: 40px; padding-bottom: 30px;}
  .dashboard-right.thanks_msg h5 {line-height: 40px;}
  .list_title a {font-size:26px; color:#333; text-transform: capitalize;}
  .list_container p{color:#333; float:left; padding-right:30px;}
  .btn-right {float:right; }
  .ShowBookList {padding-bottom: 40px;}
  .page-title {text-align:center; width:100%; padding:20px 0; border-bottom:1px solid #ccc; margin-bottom: 40px;}
  .cart-content {color:#666;}
  .terms {font-size:10px}
  .dashboard-cart {background:#fff; padding:20px 50px;}
  .with_border_right{border-right:1px solid #ddd;}
  .dashboard-cart .col-md-6 {padding-left:40px !important;}
  .dashboard-cart h4 {padding-bottom: 20px;}
  .border-right {border-right:1px solid #ddd; }
  #partitioned {
    padding-left: 15px;
    letter-spacing: 42px;
    border: 0;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 300px;
    min-width: 300px;
    background-color: transparent;
  }
  
  #divInner{
    left: 0px;
    position: sticky;
  }
  
  #divOuter{
    width: 200px; 
    overflow: hidden;
  }

  .profile_right {border-left:1px solid #ddd; padding-top:100px; padding-left:30px;}

  h4 img {margin-right:10px;}
  .list_container table {width:100%; background:#fff;  }
  .list_container table th {text-align:left; color:#000; padding:8px 5px; border:1px solid #ddd;}
  .list_container table td a, .list_container table td .lang1 {background:#3aaf9f; color:#fff; padding:8px 10px; border-radius:8px 0px 8px 8px;}
  .list_container table td {color:#666; border:1px solid #ddd; padding:8px 5px; }
  .list_container table th, .list_container table td {padding:20px;}
  .page-title {color:#999; text-align:center}

  .form-group.col-md-6, .form-group.col-md-4, .form-group.col-md-12, .form-group.col-md-3,.form-group.col-md-8 {padding:10px 15px;}

  .form-control {background:#f3f3f3;}
  .form-control.button-nav {background:#3aaf9f}
  
  .ShowBookList .container h2 {text-align:center; color:#333; padding:20px 0}

  .page-title .btn-right a{color:#fff;}
  #language {margin-top:20px;}
  .language {margin-top:20px;}
  .language button {margin:0 3px;}


  
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
  position:absolute;
  top:40%;
  left:50%;
  transform:translate(-50%,-50%);
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#loader-container {display:none; height:100%; width:100%; background:#fff; opacity:1; position:fixed; top:0; left:0}

.lan {margin:8px 2px;}

.main-lan {float:right; margin-top:20px;}

.language img, .lan img {width:40px; height:24px;}

.language button, .lan {border:0px; box-shadow:0px 0px 0px 0px; background:transparent}

.col-md-3.language{margin-top:10px;}

.dash_box1 {background:#4B4CA8;  }
.dash_box h2, .dash_box p {width:100%; color:#fff; }
.dash_box {margin-top:40px; float:left; width:100%; padding:20px;  min-height:140px; text-align:left;} 
.dash_box2 {background:#FE8C05; }
.dash_box3 {background:#A1D8FF; }
.dash_box4 {background:#45E085; }
.dashboard {padding-left:50px; padding-right:50px; background:#F0F6F6;}
.notification_list {margin-bottom:40px;}
.notification_list h5 {padding-bottom:30px;}
.notification_list ul {padding:0;  overflow-y:auto; height:300px;}
.notification_list ul li {width:100%; float:left; list-style: none; padding-bottom:12px; padding-top:15px; border-bottom:1px solid #ddd;}
.notification_list ul li p {margin-bottom:0; font-weight:600; font-size:14px; color:#999}
.notification_list ul li span {font-size:12px; color: #000;}
.dash_box span {float:left;}
.dash_box span p {font-size:12px; letter-spacing:0.3px; font-weight:600;}
.dash_box span h2 {font-weight:500;}
.dash_box span img {margin-top:10px; margin-right: 8px;}
.dashboard .col-md-3 {padding-left:5px; padding-right:5px;}
.arrow {position: relative;}
.arrow img{position: absolute; }
.google_login {background-color:transparent !important; box-shadow:none !important; background-image: url(./img/g+.png); background-size: contain; background-position: center 3px; background-repeat: no-repeat}





.language button:first-child {border-right:1px solid #fff; padding-right:6px;}

.footer-services {display:none;}
footer .container .row .col-md-3:first-child {padding-top:10px;}

.navbar-brand img {width:120px;}

.google_login {background-image:url(./img/g+.png)}

.phoneOtp{float:left; width:100%;}
.countryCode {float:left; width:20%; margin-top:5px; padding: 11px; background:#fff; border:0px;}
.phoneNumber {float:left; width:76% !important; margin-left:12px !important; padding-left:20px !important; }

.reviewTop  h2{font-size:30px;   float:left; }
.reviewList  {background:#fff; padding:10px; border-bottom:1px solid #ddd;}
.reviewTop span {float:right; font-size:14px; padding-top:8px;  }

.reviewTop {width:100%; margin-top:60px; float:left; padding-bottom:5px; margin-bottom:15px; border-bottom:1px solid #ddd;}
.reviewList h4 {float:left; width:100%; padding-bottom:0; font-size:16px; text-transform:capitalize;}
.reviewList p {font-size:13px; }
.btn-success a {color:#fff;}

.form-check.col-md-4 {padding-left:10px;}
.fa.fa-star.checked{color:#FE8C05;}

.reviewList > h4 > span {margin-left: 5px;}

/* Ratings CSS */

.rating {
  display: inline-block;
  position: relative;
  height: 50px;
  line-height: 50px;
  font-size: 50px;
}

.rating label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}

.rating label:last-child {
  position: static;
}

.rating label:nth-child(1) {
  z-index: 5;
}

.rating label:nth-child(2) {
  z-index: 4;
}

.rating label:nth-child(3) {
  z-index: 3;
}

.rating label:nth-child(4) {
  z-index: 2;
}

.rating label:nth-child(5) {
  z-index: 1;
}

.rating label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.rating label .icon {
  float: left;
  color: transparent;
}

.rating label:last-child .icon {
  color: #000;
}

.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
  color: orange;
}

.rating label input:focus:not(:checked) ~ .icon:last-child {
  color: #000;
  text-shadow: 0 0 5px orange;
}

.special_cost {display:none;}

.special {padding:20px 15px 0px;}

.input_checkbox {padding:10px !important;}

.get_sms .col-md-11 {text-align:left; padding-top:2px;}
.get_sms {padding-top:15px;}
/*.lan, .main-lan, .language {display:none;} */

.social li:nth-child(3) {display:none;}
.google_login {background-image:none !important}

.google_login > div {background:transparent !important; padding:0px !important}

.terms {padding-top:50px; float:left; }
.terms p {font-size:14px;}
.contact_head {padding:120px 0 20px 0; text-align:center}

.reset-password1 {text-align:right; position: relative;}

.autocomplete-container {
  position: relative;
}

.lang1 {cursor:pointer; color:rgb(0, 140, 255);}

.btn-size {font-size:14px !important; margin:0 2px;}

input:focus {
  outline-color: #c5d9d9; /* Change the outline color to blue */
  outline-style: solid; /* Use a solid outline style */
  outline-width: 1px; /* Set the outline width to 2px */
  border: 0px solid transparent; /* Add a custom border */
  
}

.resend1 { border:0px; color:#999; cursor:default; }
.resend {
  background:transparent; border:0px; color:#4B4CA8;
  display:none;
}
.resend:hover {background:transparent; color:#3aaf9f}
.send-button {margin-right:20px !important;}


.btn-info {font-size:14px !important; margin:0 2px;}


 .list_container table td a, .list_container table td span.lang {margin:2px 0; padding:5px 8px; float:left; font-size:14px;  }

.lng {color:#282c34}

.requestlist {color:#333; width:100%; text-align:center}
.list_title {text-align:left}

.right_section_p{background:#dcc7b3;}

.left_section_p {background-image:none !important; background:#23564f;}

textarea[name="description"].form-control{min-height:180px;}

.how_we_work .feature {min-height:500px !important;}

.fb-button {margin-top:5px;}

.list_left {width:70%;float:left;}
.list_right{width:30%; float:left; margin-top:40px;}
.btn-right1{min-width:140px;}
.btn-success.btn-right {border-radius:8px 0 8px 8px;  margin-bottom:10px; background:transparent; font-size:18px !important; color:green;border:none; cursor:text; font-weight:bold;}
.btn-success.btn-right {margin-bottom:10px;}

#content_description {font-size:16px; font-weight: 500; padding-top:8px; }

.center_text {text-align:center}
.btn.btn-success, .btn.btn-danger, .mt-2{margin:2px 0 !important}





.tooltip-container {
  position: relative;
  display: inline-block;
}

.question-mark {
  cursor: pointer;
  font-size: 18px;
  color: #3aaf9f !important;
  font-weight: bold;
}

.tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #3aaf9f;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
  width: 100%;
  min-width:200px;
  color:#fff;
}

.tooltip-container:hover .tooltip {
  display: block;
  opacity:1;
}

.nav-link {font-size:18px !important}

.licence_buttons {margin-top:50px;}
.licence_buttons button {width:100%; }
.licence_buttons .btn-primary {margin-top:0 !important}

.resend {width:100%; text-align:center}

.dash-class{width:70%; float:left;}
.dash-button {float: right;
  width: 15%;
  background: #f3f3f3;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;}

  

  .card-container:has(.list_container .status1){background:#66ff66;}
  .card-container:has(.list_container .status3){background:#4dc3ff; }
  .card-container:has(.list_container .status2){background:#ff9999;}

  .mt-4.space-y-2.text-gray-700 li {list-style: none; font-size:18px;}
  .max-w-md.mx-auto.bg-white.p-6.rounded-lg.shadow-lg.text-center button {float:none; font-size:20px !important; width:auto; padding:10px 65px;}
  .max-w-md.mx-auto.bg-white.p-6.rounded-lg.shadow-lg.text-center {padding:26px 0; border-radius: 10px; background:#f3f3f3 !important; border:1px solid #ddd}
  .text-xl.font-semibold {font-size:36px;}
  .text-gray-600.mt-2 {font-size:20px;}
  .text-3xl.font-bold.mt-4 {font-size:24px; font-weight: bold;}
  footer > .container {display: none;}
  footer {background:none !important; padding-top:0px !important; min-height:0 !important;}
  .footer_bottom p {margin:0; line-height: 36px;}
  footer p a {color:#fff; margin:0 5px;}

  

  .area_of_expertise::after, .card-box label::after{ content:"*"; color:red; margin-left:1px; font-size:14px; position:absolute}
  /* Specifically target checkboxes and remove the asterisk */

  .check-box label::after, .switch::after, .check-box::after {
    content: "" !important ;
  }
.fees .col-md-6 {padding-left:4px;}
.radius .col-md-6, .fees .col-md-6:first {padding:0 4px; padding-left:10px;} 
.radius .col-md-6 span, .fees .col-md-6 span {line-height:30px; font-weight: 600;} 
.review_pending p {text-align: center;
  color: red;
  font-size: 30px;
  padding-top: 20px;}

  .dash_menu1 .left_menu ul li {text-transform: capitalize;}

  .form-register11 form{max-width: 304px;}

  